@import "./sass/vendors/bootstrap.scss";

@import "./sass/abstracts/mixins";
@import "./sass/abstracts/variables";
@import "./sass/abstracts/helpers";

@import "./sass/base/base";
@import "./sass/base/typography";

@import "./sass/components/buttons";

@import "./sass/layout/footer";
@import "./sass/layout/sections";

@import "./sass/sections/about";
@import "./sass/sections/contact";
@import "./sass/sections/hero";
@import "./sass/sections/projects";

.text-color {
  color: $primary-color;
}

.skill-card {
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
  box-shadow: 0 4px 6px $primary-color;
}

.skill-card h3 {
  margin-bottom: 10px;
}

.skill-bar {
  position: relative;
  display: block;
  height: 30px;
  background: #787878;
  border-radius: 3px;
  overflow: hidden;
}

.skill-bar .skill-bar-fill {
  height: 100%;
  transition: width 0.5s;
}

.skill-bar[data-skill="python"] .skill-bar-fill {
  width: 90%; /* Changez cette valeur pour refléter votre compétence */
  background: #306998; /* Couleur de Python */
}

.skill-bar[data-skill="javascript"] .skill-bar-fill {
  width: 40%; /* Changez cette valeur pour refléter votre compétence */
  background: #f7df1e; /* Couleur de JavaScript */
}
.skill-bar[data-skill="fastapi"] .skill-bar-fill {
  width: 60%; /* Changez cette valeur pour refléter votre compétence */
  background: #0eb989; /* Couleur de JavaScript */
}
.skill-bar[data-skill="fastapi"] .skill-bar-fill {
  width: 60%; /* Changez cette valeur pour refléter votre compétence */
  background: #0eb989; /* Couleur de JavaScript */
}

.skill-bar[data-skill="dpy"] .skill-bar-fill {
  width: 90%;
  background: #306998; /* Changez cette valeur pour refléter votrecompetence */
}
.skill-bar[data-skill="reactjs"] .skill-bar-fill {
  width: 30%;
  background: #61dafb; /* Changez cette valeur pour refléter votrecompetence */
}

.skill-bar .skill-bar-fill span {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

* {
  cursor: none;

  @media screen and (hover: none) and (pointer: coarse) {
    cursor: default;
  }
}

body {
  cursor: default;
}

.cursor {
  @media screen and (hover: none) and (pointer: coarse) {
    display: none;
  }

  @mixin cursor-position {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }

  .cursor-ball {
    @include cursor-position;

    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: $white;
    mix-blend-mode: difference;

    &.button-hover {
      background: $purple;
    }
  }

  .cursor-ball-follower {
    @include cursor-position;

    transition: 100ms ease;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 1px solid $white;
    background-color: rgba(0, 0, 0, 0.2);
    //mix-blend-mode: difference;

    &.button-hover {
      border: 1px solid $purple;
      height: 50px;
      width: 50px;
    }

    &.click {
      height: 4px;
      width: 4px;
    }
  }
}

.emoji-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espace entre les images */
}

.emojis {
  display: inline-block;
  width: 30px; /* Ajustez la taille selon vos besoins */
  height: 30px;
}